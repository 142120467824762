/* eslint-disable */
import { authRequest } from './auth';

const deletetemplate = (templatebody) => {
  const extraParameters = templatebody;
  return authRequest.delete('/api/templates/' + templatebody.id + '/', extraParameters)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};

export { deletetemplate }; // eslint-disable-line import/prefer-default-export
