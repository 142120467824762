/* eslint-disable */
import { authRequest } from './auth';

const updatetemplate = (templatebody) => {
  const extraParameters = templatebody;
  return authRequest.put('/api/templates/' + templatebody.id + '/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { updatetemplate }; // eslint-disable-line import/prefer-default-export
