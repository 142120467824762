import { authRequest } from './auth';

const createtemplate = (templatebody) => {
  const extraParameters = templatebody;
  return authRequest.post('/api/templates/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { createtemplate }; // eslint-disable-line import/prefer-default-export
