import { authRequest } from './auth';

const fetchtemplates = async () => {
  const extraParameters = {};
  return authRequest.get('/api/templates/', extraParameters)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
};

export { fetchtemplates }; // eslint-disable-line import/prefer-default-export
