<template>
  <div class="templates-container">
    <div class="filter-container">
      <!-- <el-input
        v-model="listQuery.title"
        placeholder="类别"
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      /> -->
      <!-- <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      > -->
      <!-- 查找
      </el-button> -->
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate"
      >
        新增
      </el-button>
    </div>

    <el-table
      v-loading="listLoading"
      :data="list"
      border
      fit
      highlight-current-row
      :default-sort = "{prop: 'category', order: 'ascending'}"
      style="width: 100%"
    >
      <el-table-column
        align="center"
        label="ID"
        type="index"
        width="50">
      </el-table-column>
      <!-- <el-table-column align="center" label="ID" width="80">
        <template slot-scope="{ row }">
          <span>{{ row.id }}</span>
        </template>
      </el-table-column> -->

      <el-table-column
        align="center"
        label="Category"
        prop="category"
        sortable
        min-width="50"
        width="230"
      >
        <template slot-scope="{ row }">
          <span>{{ row.category }}</span>
        </template>
      </el-table-column>

      <!-- <el-table-column align="center" label="Content" min-width="200">
        <template slot-scope="{ row }">
          <el-button type="text" @click="handleViewcontent(row)"
            >content</el-button
          >

          <el-dialog title="Content" :visible.sync="dialogFormVisible">
            <el-form ref="form" :model="viewcontent">
              <div>
                <prism-editor
                  class="my-editor"
                  v-model="viewcontent.content"
                  :highlight="highlighter"
                  readonly
                  line-numbers
                ></prism-editor>
              </div>
            </el-form>

            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogFormVisible = false"
                >确 定</el-button
              >
            </div>
          </el-dialog>
        </template>
      </el-table-column> -->

      <el-table-column min-width="200" align="center" label="Filename">
        <template slot-scope="{ row }">
          <span>{{ row.filename }}</span>
        </template>
      </el-table-column>

      <el-table-column
        label="Actions"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="{ row }">
          <el-button round type="primary" size="mini" @click="handleUpdate(row)">
            修改
          </el-button>
          <el-button
            round
            size="mini"
            type="danger"
            @click="handleDelete(row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form
        ref="templateForm"
        :model="templateform"
        label-position="left"
        label-width="100px"
        style="width: 90%; margin-left: 20px"
      >
        <el-form-item label="Type" prop="category">
          <el-select
            v-model="templateform.category"
            class="filter-item"
            placeholder="Please select"
          >
            <el-option
              v-for="item in templateTypes"
              :key="item.key"
              :label="item.display_name"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="File name" prop="filename">
          <el-input v-model="templateform.filename" />
        </el-form-item>
        <el-form-item label="Content">
          <prism-editor
            class="my-editor"
            v-model="templateform.content"
            :highlight="highlighter"
            line-numbers
          ></prism-editor>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false"> 取消 </el-button>
        <el-button
          type="primary"
          @click="dialogStatus === 'create' ? createData() : updateData()"
        >
          确认
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { fetchtemplates } from "../../services/api/fetchtemplates";
import { mapGetters } from "vuex";

import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css"; // import the styles somewhere

// import highlighting library (you can use any library you want just return html string)
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
import { createtemplate } from "../../services/api/createtemplate";
import { updatetemplate } from "../../services/api/updatetemplate";
import { deletetemplate } from "../../services/api/deletetemplate";

const templateTypes = [
  { key: "PRE_DEPLOY", display_name: "Pre-deploy" },
  { key: "DEPLOY", display_name: "Deploy" },
  { key: "POST_DEPLOY", display_name: "Post-deploy" },
  { key: "TERRAFORM", display_name: "Terraform file" },
];

export default {
  name: "templates",
  computed: {
    ...mapGetters(["name"]),
  },
  components: {
    PrismEditor,
  },
  data() {
    return {
      list: [],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
      },
      dialogFormVisible: false,
      viewcontent: {},
      // dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "Edit",
        create: "Create",
      },
      templateform: {
        id: undefined,
        content: "",
        filename: "",
        category: "",
      },
      templateTypes,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      fetchtemplates()
        .then((data) => {
          this.list = data;
        })
        .catch((error) => {
          alert(error);
        });
    },
    highlighter(code) {
      return highlight(code, languages.js); //returns html
    },
    // handleViewcontent(row) {
    //   this.viewcontent = Object.assign({}, row); // copy obj
    //   this.dialogFormVisible = true;
    // },
    resettemplateform() {
      this.templateform = {
        id: undefined,
        content: "",
        filename: "",
        category: "",
      };
    },
    handleUpdate(row) {
      this.templateform = Object.assign({}, row); // copy obj
      this.dialogStatus = "update";
      this.dialogFormVisible = true;

      // this.$nextTick(() => {
      //   this.$refs['dataForm'].clearValidate()
      // })
    },
    updateData() {
      // const tempData = Object.assign({}, this.templateform)
      updatetemplate(this.templateform)
        .then((data) => {
          // this.list = data;
          var res = data.code;
          if (res === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            const index = this.list.findIndex(
              (v) => v.id === this.templateform.id
            );
            this.list.splice(index, 1, this.templateform);
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });

      this.dialogFormVisible = false;
    },
    handleCreate() {
      this.resettemplateform();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      // this.$nextTick(() => {
      //   this.$refs['dataForm'].clearValidate()
      // })
    },
    createData() {
      createtemplate(this.templateform)
        .then((data) => {
          // this.list = data;
          var res = data.code;
          if (res === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            this.templateform.id = data.msg;
            this.list.unshift(this.templateform);
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });

      this.dialogFormVisible = false;
    },
    handleDelete(row) {
      this.templateform = Object.assign({}, row); // copy obj

      deletetemplate(this.templateform)
        .then((response) => {
          var res = response.status;

          // no data returned for status 204.
          if (res === 204) {
            this.$message({
              message: "操作成功",
              type: "success",
            });

            const index = this.list.findIndex(
                (v) => v.id === row.id
              );

            this.list.splice(index, 1);
          } else {
            this.$message({
              message: "操作失败",
              type: "error",
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.templates {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}

/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #2d2d2d;

  color: #ccc;

  // margin: 5px, 30px;

  /* you must provide font-family font-size line-height. Example: */
  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}

.filter-container {
  padding-bottom: 10px;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}
</style>
